.react-kanban-board {
    padding: 5px;
}

.react-kanban-board > div {
    width: 100%;
    display: flex;
}

.react-kanban-card {
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin-top: 16px;
    border: 2px solid #cdcdcd;
    width: 100%;
}

.react-kanban-card-skeleton,.react-kanban-card-adder-form,.react-kanban-card {
    box-sizing: border-box;
    flex: 1;
}

.react-kanban-card-adder-form {
    border: 1px solid #eee;
}

.react-kanban-card--dragging {
    box-shadow: 2px 2px gray
}

.react-kanban-card__description {
    padding-top: 10px
}

.react-kanban-card__title {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    font-weight: 700;
    display: flex;
    justify-content: space-between
}

.react-kanban-column {
    padding: 15px;
    border-radius: 10px;
    background-color: #F3F3F3;
    margin: 5px;
    flex: 1;
}

.react-kanban-column > div:last-child > div > div {
    display: block !important;
}

.react-kanban-column input:focus {
    outline: none
}

.react-kanban-card-adder-form {
    border-radius: 3px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 7px
}

.react-kanban-card-adder-form input {
    border: 0px;
    font-family: inherit;
    font-size: inherit
}

.react-kanban-card-adder-button {
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid #ccc;
    transition: .3s;
    border-radius: 3px;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700
}

.react-kanban-card-adder-button:hover {
    background-color: #ccc
}

.react-kanban-card-adder-form__title {
    border-bottom: 1px solid #eee;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0
}

.react-kanban-card-adder-form__title:focus {
    outline: none
}

.react-kanban-card-adder-form__description {
    width: 100%;
    margin-top: 10px
}

.react-kanban-card-adder-form__description:focus {
    outline: none
}

.react-kanban-card-adder-form__button {
    background-color: #eee;
    border: none;
    padding: 5px;
    width: 45%;
    margin-top: 5px;
    border-radius: 3px
}

.react-kanban-card-adder-form__button:hover {
    transition: .3s;
    cursor: pointer;
    background-color: #ccc
}

.react-kanban-column-header {
    /*padding-bottom: 10px;*/
    font-weight: 700
}

.react-kanban-column-header input:focus {
    outline: none
}

.react-kanban-column-header__spacer {
    display: flex;
    justify-content: space-between;
}

.react-kanban-column-header__spacer span:last-child {
    /*display: none;*/
}

.react-kanban-column-header__button {
    color: #333;
    background-color: #fff;
    border-color: #ccc
}

.react-kanban-column-header__button:hover,.react-kanban-column-header__button:focus,.react-kanban-column-header__button:active {
    background-color: #e6e6e6
}

.react-kanban-column-adder-button {
    border: 2px dashed #eee;
    height: 132px;
    margin: 5px
}

.react-kanban-column-adder-button:hover {
    cursor: pointer
}
