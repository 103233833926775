@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, -6px) scale(0.75) !important;
}

a,
p,
input,
textarea,
button,
select {
  font-family: 'Noto Sans JP';
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Medium-Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: local('Sailec'), url('./assets/fonts/Sailec/Sailec-Bold-Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
